import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/HomeView.vue')
  },
  {
    path: '/production/fqd',
    name: '防窃电',
    component: function () {
      return import('../views/production/fqd.vue')
    }
  },
  {
    path: '/production/yccb',
    name: '远程抄表',
    component: function () {
      return import('../views/production/yccb.vue')
    }
  },
  {
    path: '/production/BMS',
    name: '蓄电池',
    component: function () {
      return import('../views/production/BMS.vue')
    }
  },
  {
    path: '/production/hotline',
    name: '政府热线',
    component: () => import('../views/production/hotline.vue')
  },
  {
    path:'/production/DCDU',
    name:'基站智能DCDU',
    component:()=>import('../views/production/DCDU.vue')
  },
  {
    path:'/production/EBM',
    name:'蓄电池单体检测模块',
    component:()=>import('../views/production/EBM.vue')
  },
  {
    path: '/solution',
    name: '解决方案',
    component: () => import('../views/solution/index.vue')
  },
  {
    path: '/example',
    name: '应用案例',
    component: () => import('../views/example/index.vue')
  },
  {
    path: '/aboutus',
    name: '关于我们',
    component: () => import('../views/aboutus/index.vue')
  }
]

const router = new VueRouter({
  routes
})
// 在router.js中添加路由前置守卫，在路由跳转之前，完成让页面回到顶部
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
  next();
});
export default router
